import { navigate as gatsbyNavigate } from 'gatsby'
import isBrowser from './isBrowser'
import { getCookieValue } from '../helpers/cookies'

const removeParam = (url, param) => {
    const hostExample = 'https://example.com' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)
    let params = new URLSearchParams(urlObject.search)
    url = url.replace(urlObject.search, '')
    params.delete(param)

    return `${url}${params.toString() ? '?' : ''}${params.toString()}`

}

export const getUrl = (url) => {    
    
    const hostExample = 'https://example.com/' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)    
    url = url.replace(urlObject.search, '')
    

    let urlParams = new URLSearchParams(urlObject.search || (isBrowser() && window.location.search))
    let param = 'creditApplicationId'

    let nameChannel = 'channel'
    let channel = getCookieValue('channel');
    if (channel !== null) {
        if (!urlParams.has(nameChannel)) {
            urlParams.append(nameChannel, channel)
        }
    }

    if (!urlParams.has(param)) {
        const flujo = JSON.parse(isBrowser() ? sessionStorage.getItem('flujo') : '{}')
        let id = flujo?.creditApplicationId
        if (id) {
            urlParams.append(param, id)
        }
    }


    return `${url}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`
}

export const navigate = (url, options) => {
    gatsbyNavigate(getUrl(url), options)
}

export const navigateDelete = (url, del = [], useHelper = true) => {
    let urlNavigate = url
    const hostExample = 'https://example.com/' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)
    if (!urlObject.search) {
        urlNavigate += window.location.search
    }

    del.forEach(item => {
        urlNavigate = removeParam(urlNavigate, item)
    })

    if (useHelper) {
        navigate(urlNavigate)
    } else {
        gatsbyNavigate(urlNavigate)
    }

}

export const navigateNative = (url) => {
    window.location.href = getUrl(url)
}

export const reloadPage = () => {
    window.location.reload()
}